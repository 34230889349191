export const ORDER_STATUS = {
  DRAFT: 0,
  CLOSED: 1,
  FAILED: 2,
  EXPIRED: 3,
  VOIDED: 4,
  PICKED_UP: 7,
  READY: 8,
  CONFIRMED: 9,
  PENDING_PAYMENT: 11,
};

export const ORDER_ITEM_STATUS = {
  DRAFT: 0,
  CLOSED: 1,
  VOIDED: 4,
  READY: 8,
  PLACED: 9,
};

export const ORDER_ITEM_TYPE = {
  SET: 1,
  ALA_CARTE: 2,
  DELIVERY: 9,
  BUNDLED: 11,
  PROMO: 12,
  VOUCHER: 21,
  DISCOUNT: 22,
  ADJUSTMENT: 29,
  PAYMENT: 31,
};

export const PG_USER_TX_STATUS = {
  PENDING: 0,
  ACTIVE: 1,
  SUSPENDED: 2,
  FAILED: 3,
  TIMEOUT: 9,
};
