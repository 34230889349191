<template>
  <div>
    <template v-for="(set, setIndex) in order.items">
      <div :key="setIndex" :class="{ 'mb-1': order.items.length > 1 }">
        <div class="font-1dot1r font-weight-medium">
          {{ set }}
        </div>
      </div>
    </template>

    <v-divider class="my-2"></v-divider>

    <div v-if="isDelivery">
      <div class="d-flex justify-space-between mb-2">
        <div class="font-weight-medium font-1dot1r">{{ $t('orders.lbl.subtotal') }}</div>
        <div class="font-weight-medium font-1dot1r">{{ `${$t('lbl.rm')}${subtotal}` }}</div>
      </div>
      <div class="d-flex justify-space-between mb-2">
        <div class="font-weight-medium font-1dot1r">{{ $t('orders.lbl.deliveryFee') }}</div>
        <div class="font-weight-medium font-1dot1r">
          <span v-if="order.discDeliveryFee > 0" class="line-through mr-2" style="color:var(--grey-dark-1)">{{
            `${$t('lbl.rm')}${deliveryFee}`
          }}</span>
          <span>{{ `${$t('lbl.rm')}${deliveryFeeAfterDisc}` }}</span>
        </div>
      </div>
    </div>

    <template v-if="order.discAmt > 0">
      <template v-if="!isDelivery">
        <div class="d-flex justify-space-between align-center mb-2">
          <div class="font-weight-medium font-1dot1r">{{ $t('orders.lbl.subtotal') }}</div>
          <div class="font-weight-medium font-1dot1r">{{ `${$t('lbl.rm')}${subtotal}` }}</div>
        </div>
      </template>

      <div class="d-flex justify-space-between align-center mb-2">
        <div class="font-weight-medium font-1dot1r">{{ 'Discount' }}</div>
        <div class="font-weight-medium font-1dot1r">{{ `- ${$t('lbl.rm')}${priceFormat(order.discAmt)}` }}</div>
      </div>
    </template>

    <v-divider class="my-2"></v-divider>
    <div class="d-flex justify-space-between">
      <div class="font-weight-bold font-1dot1r">{{ $t('lbl.total') }}</div>
      <div class="font-weight-bold font-1dot2r font-red-common">{{ `${$t('lbl.rm')}${priceFormat(order.amt)}` }}</div>
    </div>
  </div>
</template>
<script>
import { DELIVERY_CHANNEL } from '@/common/enum/checkout';
export default {
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isDelivery() {
      return this.order.channel == DELIVERY_CHANNEL.DELIVERY;
    },
    subtotal() {
      const nettDeliveryFee = this.order.deliveryFee - this.order.discDeliveryFee;
      return this.priceFormat(this.order.amt - nettDeliveryFee + this.order.discAmt);
    },
    deliveryFee() {
      return !this.isUndefinedNullOrEmpty(this.order.deliveryFee)
        ? this.priceFormat(this.order.deliveryFee)
        : this.priceFormat(0);
    },
    deliveryFeeAfterDisc() {
      if (this.isUndefinedNullOrEmpty(this.order.discount)) return this.deliveryFee;
      return this.priceFormat(this.deliveryFee - this.order.discDeliveryFee);
    },
  },
};
</script>
