<template>
  <div class="d-flex flex-column h-100 px-2">
    <div class="order-wrap pa-4 mb-3">
      <div class="d-flex justify-space-between">
        <div class="font-1dot25r font-weight-bold">Order #{{ orderDetails.seq }}</div>
        <div class="font-1dot25r">{{ orderDetails.cdate ? setTimeZone(orderDetails.cdate, timeFormat) : orderDetails.time }}</div>
      </div>
      <v-divider class="title-divider my-2"></v-divider>

      <ReceiptSummary v-if="!isUndefinedNullOrEmpty(orderData)" :order="orderData" />
    </div>

    <div v-if="isPickup">
      <div class="order-wrap pa-4">
        <div class="font-1dot25r font-weight-bold mb-2">
          {{ $t('pickup.lbl.pickup') }}
        </div>
        <v-divider class="title-divider mb-2"></v-divider>
        <div class="font-1dot1r font-weight-regular mb-1">
          <div class="mb-1">
            {{ $t('lbl.store') }}: <span class="font-weight-medium">{{ orderData.store || '-' }}</span>
          </div>
          <div class="mb-1">
            {{ $t('pickup.lbl.pickupTime') }}:
            <span class="font-weight-medium">{{ orderData.doDate ? formatTime(orderData.doDate) : $t('lbl.now') }}</span>
          </div>
          <!-- <div class="mb-1">{{ $t('profile.lbl.address') }}: <span class="font-weight-bold">{{ `${orderData.address}, ${orderData.postcode} ${orderData.city}` }}</span></div> -->
        </div>
        <div class="text-right font-1dot25r font-weight-bold mt-3">
          {{ $t('map.orderStatus')[orderData.status] }}
        </div>
      </div>
    </div>
    <div v-else-if="isDelivery">
      <div class="order-wrap pa-4">
        <div class="font-1dot25r font-weight-bold mb-2">
          {{ $t('delivery.lbl.delivery') }}
        </div>
        <v-divider class="title-divider mb-2"></v-divider>
        <div class="font-1dot1r font-weight-regular mb-1">
          <div class="mb-1">
            {{ $t('lbl.store') }}: <span class="font-weight-medium">{{ orderData.store || '-' }}</span>
          </div>
          <div class="mb-1">
            {{ $t('delivery.lbl.deliveryTime') }}:
            <span class="font-weight-medium">{{ orderData.doDate ? formatTime(orderData.doDate) : $t('lbl.now') }}</span>
          </div>
          <div class="mb-1">
            {{ $t('profile.lbl.address') }}:
            <span class="font-weight-medium">{{ `${orderData.address}, ${orderData.postcode} ${orderData.city}` }}</span>
          </div>
        </div>
        <div class="text-right font-1dot25r font-weight-bold mt-3">
          {{ $t('map.orderStatus')[orderData.status] }}
        </div>
      </div>
    </div>

    <div v-if="orderDetails.cancelled == 1" class="my-3 red--text font-1dot25r font-weight-bold text-right">
      <i>{{ $t('orders.lbl.cancelled') }}</i>
    </div>
    <div v-else class="my-3">
      <template v-if="allowCancel">
        <AppBtn class="btn-black" block :height="42" @click="promptReason">{{ $t('orders.act.cancelOrder') }}</AppBtn>
      </template>
    </div>
    <CommonModal v-model="cancelModal" :modal-size="'modal-xs'" :title="$t('orders.lbl.cancelOrder')" @close="resetFields">
      <template #body-content>
        <ValidationObserver ref="observer">
          <div class="my-3">
            <label class="font-14">
              {{ $t('lbl.remarks') }}
              <span class="font-red">{{ rules.remarks.required ? '*' : '' }}</span>
            </label>
            <TextField v-model="remarks" :rules="rules.remarks" :label="$t('lbl.remarks')" />
          </div>
        </ValidationObserver>
      </template>
      <template #footer-content>
        <div class="text-right">
          <AppBtn class="btn-black" :height="42" :loading="loading" @click="cancelOrder">{{ $t('act.confirm') }}</AppBtn>
        </div>
      </template>
    </CommonModal>
    <SuccessModal v-model="successModalShow" :msg="successModalMsg" />
  </div>
</template>
<script>
import { DELIVERY_CHANNEL, SCHEDULE_TYPE } from '@/common/enum/checkout';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { orderApi } from '@/api/order';
import { ORDER_STATUS } from '@/common/enum/order';
import CommonModal from '@/components/Modal';
import SuccessModal from '@/components/Modal/SuccessModal';
import ReceiptSummary from '@/views/Order/receipt-summary';
export default {
  name: 'OrderHistoryDetails',
  components: {
    CommonModal,
    SuccessModal,
    ReceiptSummary,
  },
  data() {
    return {
      orderHistory: {},
      remarks: '',
      cancelModal: false,
      successModalShow: false,
      successModalMsg: this.$t('orders.success.cancel'),
      rules: {
        remarks: { required: true, max: 50 },
      },
      loading: false,
      orderData: {},
    };
  },
  computed: {
    ...mapState({
      timeFormat: state => state.app.timeFormat,
    }),
    ...mapGetters(['getOrderHistory']),
    orderDetails() {
      if (this.isUndefinedNullOrEmpty(this.$route.params.orderId)) return;
      return this.getOrderHistory.find(item => item.orderId == this.$route.params.orderId);
    },
    allowCancel() {
      const condition1 = [ORDER_STATUS.DRAFT];
      const condition1Allowed = condition1.indexOf(this.orderData?.status) != -1;
      return condition1Allowed;
    },
    isPickup() {
      return this.orderData.channel == DELIVERY_CHANNEL.PICK_UP;
    },
    isDelivery() {
      return this.orderData.channel == DELIVERY_CHANNEL.DELIVERY;
    },
    SCHEDULE_TYPE() {
      return SCHEDULE_TYPE;
    },
  },
  created() {
    this.SET_DRAWER_TITLE('History');
    this.getOrderDetails(this.$route.params.orderId, this.orderDetails.userId);
  },
  methods: {
    ...mapActions(['updateOrderHistory']),
    ...mapMutations(['SET_DRAWER_TITLE']),
    resetFields() {
      this.remarks = '';
    },
    promptReason() {
      this.cancelModal = true;
    },
    async cancelOrder() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      const data = {
        orderId: this.orderDetails.orderId,
        seq: this.orderDetails.seq,
        orderUserId: this.orderDetails.userId,
        amt: this.orderDetails.amt,
        remarks: this.remarks,
      };
      this.loading = true;
      return orderApi
        .void(data)
        .then(res => {
          this.loading = false;
          if (res.ret == '0') {
            this.cancelModal = false;
            this.remarks = '';

            this.successModalShow = true;
            setTimeout(() => {
              this.successModalShow = false;

              let orderHistory = this.getOrderHistory.find(item => item.orderId == this.$route.params.orderId);
              orderHistory.status = ORDER_STATUS.VOIDED;
              orderHistory.cancelled = 1;
              this.updateOrderHistory(orderHistory);
            }, 500);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getOrderDetails(id, userId) {
      return orderApi.detail({ id, userId }).then(res => {
        this.orderData = res.data;
        this.updateOrder(this.orderData);
      });
    },
    formatTime(dateTime) {
      let formatted = this.setTimeZone(dateTime, 'YYYY-MM-DD h:mma');
      let string1 = formatted.substr(0, 10);
      let string2 = formatted.substring(10);
      return string1.concat(',', string2);
    },
    updateOrder(orderData) {
      let orderHistory = this.getOrderHistory.find(item => item.orderId == this.$route.params.orderId);
      orderHistory.status = orderData.status;
      orderHistory.storeName = orderData.store;
      orderHistory.seq = orderHistory.status == ORDER_STATUS.PENDING_PAYMENT ? orderData.seq : orderHistory.seq;
      orderHistory.cancelled = orderData.status == ORDER_STATUS.VOIDED ? 1 : 0;

      this.updateOrderHistory(orderHistory);
    },
  },
};
</script>
<style lang="scss" scoped>
.order-wrap {
  background-color: #fff;
  border: 5px solid var(--primary-black-color);
  border-radius: 5px;
}
.title-divider {
  border-color: rgb(0 0 0 / 22%);
}
</style>
